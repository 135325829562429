exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-assets-js": () => import("./../../../src/pages/account/assets.js" /* webpackChunkName: "component---src-pages-account-assets-js" */),
  "component---src-pages-account-coupon-js": () => import("./../../../src/pages/account/coupon.js" /* webpackChunkName: "component---src-pages-account-coupon-js" */),
  "component---src-pages-account-hashrate-js": () => import("./../../../src/pages/account/hashrate.js" /* webpackChunkName: "component---src-pages-account-hashrate-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-settings-js": () => import("./../../../src/pages/account/settings.js" /* webpackChunkName: "component---src-pages-account-settings-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-reset-pass-js": () => import("./../../../src/pages/reset-pass.js" /* webpackChunkName: "component---src-pages-reset-pass-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/BlogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/Categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-page-constructor-js": () => import("./../../../src/templates/PageConstructor.js" /* webpackChunkName: "component---src-templates-page-constructor-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/pagePost.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-page-product-js": () => import("./../../../src/templates/pageProduct.js" /* webpackChunkName: "component---src-templates-page-product-js" */)
}

